<template>
  <div class="component-learn">
    <div class="container">
      <div class="subject">라이브 레슨</div>
      <div class="folders">
        <div class="folder" :class="{ opened: state.openedIdxes[idx] }" v-for="(l, idx) in defines.lessons" :key="idx">
          <div class="head" @click="toggle(idx)">
            <div class="wrapper">
              <div class="inner">
                <span class="img" :style="{ backgroundImage: `url(./assets/img/${l.ico})` }" alt="아이콘"></span>
                <div class="title">
                  <span class="text">{{ l.title }}</span>
                  <div class="badges">
                    <span class="badge badge-primary" :title="`${t}을 이용하여 수업합니다.`" v-for="(t, idx) in l.tools" :key="idx">{{ t }}</span>
                    <span class="badge badge-info" v-for="(t, idx) in l.tags" :key="idx">{{ t }}</span>
                  </div>
                </div>
                <div class="desc">{{ l.desc }}</div>
                <i class="fa fa-angle-down"></i>
              </div>
            </div>
          </div>
          <div class="body" v-if="state.openedIdxes[idx]">
            <div class="row">
              <div class="col-lg-9 core">
                <div class="section">
                  <div class="subject">수업 소개</div>
                  <div class="content">{{ l.content }}</div>
                </div>
                <div class="section">
                  <div class="subject">현재 상태</div>
                  <div class="content">{{ l.status === "opened" ? "신청 가능" : "신청 마감" }}</div>
                </div>
                <div class="section">
                  <div class="subject">신청 방법</div>
                  <div class="content">신청하기 버튼 클릭 후 메시지를 보내주세요.</div>
                </div>
                <div class="section">
                  <div class="subject">레슨 정보</div>
                  <div class="content">
                    <div class="mb-1">- 레슨 방법: 온라인 수업({{ l.tools.join(", ") }})</div>
                    <div class="mb-1">- 가능 요일: 월~토요일</div>
                    <div class="mb-1">- 가능 시간: 오전 6시~7시 & 오후 11시~12시</div>
                  </div>
                </div>
                <div class="section curriculum">
                  <div class="subject">커리큘럼</div>
                  <div class="content">
                    <table>
                      <tbody>
                        <tr v-for="(c, idx) in l.curriculums" :key="idx">
                          <th>Lesson {{ (idx >= 9 ? "" : "0") + (idx + 1) }}.</th>
                          <td>{{ c }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="section">
                  <div class="subject">안내사항</div>
                  <div class="content">
                    <table>
                      <tbody>
                        <tr>
                          <th>1.</th>
                          <td>커리큘럼은 표준안이며 사용자의 필요와 환경에 따라 변경될 수 있습니다.</td>
                        </tr>
                        <tr>
                          <th>2.</th>
                          <td>수업 시간은 사용자의 상황에 따라 조정이 가능합니다.</td>
                        </tr>
                        <tr>
                          <th>3.</th>
                          <td>수업 내용을 녹화하는 것은 가능하나 녹화 파일을 공유하는 것은 강사와 협의해주시기 바랍니다.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 side">
                <img :src="`./assets/img/${l.img}`" />
                <a :href="l.url" target="_blank" class="btn btn-point btn-block" :class="{ disabled: l.status !== 'opened' }">신청하기</a>
                <a href="https://open.kakao.com/o/g0JSgjce" target="_blank" class="btn btn-dark btn-block">문의하기</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import defines from "../defines.json";

export default {
  setup() {
    const store = useStore();
    const state = reactive({
      openedIdxes: [],
    });

    const toggle = (idx) => {
      state.openedIdxes[idx] = !state.openedIdxes[idx];
    };

    return { store, state, defines, toggle };
  },
};
</script>
<style lang="scss" scoped>
.component-learn {
  background: #f7f7f7;
  padding: 25px 0;

  .container {
    > .subject {
      font-size: 18px;
      margin-bottom: 17.5px;
      font-weight: bold;
      padding: 0 5px;
    }

    .folders {
      .folder {
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);

        .head {
          background: none;
          border: 0;
          padding: 0;

          > .wrapper {
            padding: 27.5px 30px;
            cursor: pointer;
            color: inherit;
            text-decoration: none;

            > .inner {
              position: relative;
              padding: 7.5px 15px 10px 82.5px;

              > .img {
                width: 65px;
                height: 65px;
                position: absolute;
                background-size: 100% auto;
                top: 0;
                left: 0;
              }

              .title {
                .text {
                  font-weight: bold;
                  margin-right: 6px;
                  vertical-align: middle;
                }

                .badges {
                  padding-left: 1px;
                  font-size: 0;
                  display: inline-block;
                  vertical-align: middle;

                  .badge {
                    vertical-align: middle;
                    line-height: 12px;
                    font-size: 12px;
                    height: 20px;
                    margin-right: 2.5px;
                  }
                }
              }

              .desc {
                font-size: 14px;
                margin-top: 3px;
                color: #666;
              }

              > i {
                position: absolute;
                top: 0;
                right: 0;
                padding: 22px 11px;
                font-size: 24px;
                transition: transform 0.25s;
              }
            }

            &:hover {
              background: #eee;
            }
          }
        }

        .body {
          padding: 17.5px 35px 35px 35px;
          border-top: 1px solid rgba(0, 0, 0, 0.125);

          > .row > div {
            padding-top: 15px;

            > .section {
              > .subject {
                padding: 0 0 10px 0;
                width: 100%;
                font-weight: bold;
              }

              > .content {
                table {
                  table-layout: fixed;
                  border: 0;

                  th,
                  td {
                    border: 0;
                    padding: 5px;
                    font-weight: normal;
                    vertical-align: top;
                  }
                }

                > .rect {
                  text-align: right;

                  img {
                    height: 200px;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    border-radius: 50%;
                  }
                }
              }

              &.curriculum {
                > .content table th {
                  width: 85px;
                }
              }
            }

            &.core {
              .section:not(:first-child) {
                margin-top: 35px;
              }
            }

            &.side {
              padding: 50px 0;
              text-align: center;

              img {
                max-width: 210px;
                margin-bottom: 25px;
              }

              .btn {
                margin-top: 10px;
                padding: 20px 5px;
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }

        &.opened {
          .head > .wrapper > .inner > i {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      .folders .folder {
        .head > .wrapper {
          padding: 15px;

          > .inner {
            padding-top: 0;
            padding-bottom: 0;

            .title {
              padding-top: 5px;

              .badges {
                display: block;
                margin-top: 5px;
              }
            }

            .desc {
              margin-left: -82.5px;
              margin-top: 30px;
            }

            > i {
              display: none;
            }
          }

          &[aria-expanded="false"]:hover {
            background: none;
          }
        }

        .body {
          padding: 5px 15px 15px 15px;

          > .row > div > .section > .content > .rect {
            padding-top: 35px;
            text-align: center;
          }
        }

        &:not(:last-child) {
          margin-bottom: 12.5px;
        }
      }
    }
  }
}
</style>
